body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.full {
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.just-center {
  justify-content: center;
}

.just-around {
  justify-content: space-around;
}

.just-start {
  justify-content: flex-start;
}

.box {
  border-radius: 5px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  height: 250px;
  padding: 30px;
  width: 350px;
}

.img-profile {
  border: 3px solid #6942b6;
  border-radius: 50%;
  margin-right: 1rem;
  width: 70px;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 1.3rem;
}

.user-info span {
  display: block;
}

.user-info span.info-email {
  color: #666;
  font-size: 0.8rem;
}